<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card :loading="loading">
      <v-form ref="form" lazy-validation v-on:submit.prevent>
        <v-card-title class="justify-center">
          <span class="headline">
            {{ dialogTitle }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col
                class="d-flex"
                cols="12"
                sm="6"
              >
                <v-file-input
                  v-model="image"
                  :rules="[v => (!!v || isUploaded) || 'Image is required']"
                  label="upload media"
                  accept="image/png, image/jpeg"
                  @change="updateCategoryImage()"
                />
              </v-col>
              <v-col
                class="d-flex align-center justify-center"
                cols="12"
                sm="6"
              >
                <h1
                  v-if="!isUploaded && !image"
                  align="center"
                  class="gray--text"
                >
                  no media
                </h1>
                <v-hover
                  v-else
                  v-slot:default="{ hover }"
                >
                  <v-card
                    class="image-card"
                    :elevation="hover ? 3 : 2"
                    :class="{ 'on-hover': hover }"
                  >
                    <img
                      :src="imageUrl"
                      width="200"
                      height="200"
                      class="grey lighten-2"
                    >
                    <v-fade-transition>
                      <v-overlay
                        v-if="hover"
                        absolute
                        color="#000"
                      >
                        <v-btn
                          color="red accent-2"
                          text
                          @click="deleteCategoryImage()"
                        >
                          delete
                        </v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="category.title"
                  label="العنوان"
                  :rules="[(v) => !!v || 'العنوان مطلوب']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-1">
                <v-textarea
                  v-model="category.description"
                  label="الوصف"
                  :rules="[(v) => !!v || 'الوصف مطلوب']"
                  rows="2"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <base-button
            :options="{ color: this.grayBack50 }"
            text
            @click="close"
          >
            الغاء
          </base-button>
          <base-button @click="save" options> حفظ </base-button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from "@/core/Base/Buttons/BaseButton";
import { mapActions } from "vuex";
export default {
  name: "CategoryDialog",
  props: {
    isEdit: Boolean,
    selectedCategory: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      category: {id: -1},
      image: null,
      dialog: true,
      isUploaded: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.category = {id: -1};
      this.image = null;
      this.$refs.form.resetValidation();
      this.$emit("handleClose");
    },
    async save() {
      if (!this.$refs.form.validate()) return;
      console.log(this.category);
      // update image if upload new one 
      if(this.image) this.category.thumbnail = this.image;
      else delete this.category.thumbnail
      this.$emit("handelRequest", this.category);
    },
    updateCategoryImage() {
      this.isUploaded = true;
    },
    deleteCategoryImage() {
      this.isUploaded = false;
      this.image = null;
    }
  },
  computed: {
   dialogTitle() {
     return (this.category.id === -1)? 'إضافة صنف' : 'تعديل صنف'
   },
   imageUrl() {
     return ((this.image)? URL.createObjectURL(this.image) : this.getBased(this.category.thumbnail));
   }
  },
  created() {
    if(this.selectedCategory.id !== -1) {
      this.category = this.selectedCategory
      this.isUploaded = true;
    }
  },

  components: { BaseButton },
};
</script>


<style scoped>
</style>