<template>
  <v-container id="bills" class="pa-14">
    <notifications
      group="public"
      position="bottom center"
      :style="{ marginBottom: '2px' }"
    />
    <category-dialog
      v-if="dialogVisible"
      :loading="loading"
      :selectedCategory="selectedCategory"
      @handleClose="handleClose"
      @handelRequest="handelRequest"
    ></category-dialog>
    <confirm-delete
      v-if="deleteConfirm >= 0"
      :loading="loading"
      v-bind="{ id: deleteConfirm, isOpen: true }"
      @handleClose="handleClose"
      @handelRequest="handleDelete"
    ></confirm-delete>

    <!-- table -->
    <v-row class="d-flex flex-column text-center">
      <v-col cols="12" class="mb-4">
        <h1 class="display-3 font-weight-bold mb-3 text-grey">
          أصناف التدريب
        </h1>
      </v-col>
      <v-col cols="12">
        <base-table
          v-bind="{
            headers: headers,
            moduleName: 'DashBoard/Category',
          }"
        >
          <v-btn
            class="ml-3 mb-3 mb-md-0 text-lg-h6"
            :color="primaryColor"
            dark
            @click="dialogVisible = true"
          >
            <v-icon>{{ plusIcon }}</v-icon>
            إضافة صنف
          </v-btn>
          <template v-slot:action="{ data }">
            <v-btn
              text
              icon
              color="secondary lighten-2"
              @click="handleEdit(data)"
            >
              <v-icon color="green">{{ editIcon }}</v-icon>
            </v-btn>
            <v-btn
              text
              icon
              color="secondary lighten-2"
              @click="deleteConfirm = data.category_id"
            >
              <v-icon color="red">{{ deleteIcon }}</v-icon>
            </v-btn>
          </template>
        </base-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseTable from "@/modules/DashBoard/Components/BaseTable";
import CategoryDialog from "./Components/categoryDialog";
import ConfirmDelete from "@/modules/DashBoard/Components/confirmDelete";
import { mapActions } from "vuex";

export default {
  name: "Categories",

  components: { BaseTable, ConfirmDelete, CategoryDialog },

  data() {
    return {
      headers: [
        { text: "العنوان", value: "category_title" },
        { text: "عدد الدورات", value: "count" },
        { text: "", value: "actions", align: "center" },
      ],
      selectedCategory: { id: -1 },
      dialogVisible: false,
      deleteConfirm: -1,
      loading: false,
    };
  },

  methods: {
    ...mapActions("DashBoard/Category", [
      "addCategory",
      "editCategory",
      "deleteCategory",
    ]),

    async handelRequest(data) {
      // add category case
      this.loading = true;
      if (data.id === -1) {
        delete data.id;
        await this.addCategory(this.objectToFormData(data));
      }
      // edit category case
      else {
        await this.editCategory({id: data.id, data: this.objectToFormData(data)});
      }
      this.loading = false;
      this.handleClose();
    },
    async handleDelete(data) {
      this.loading = true;
      let response = await this.deleteCategory(data.id);
      this.loading = false;
      this.handleClose()
    },
    handleClose() {
      this.selectedCategory = { id: -1 };
      this.deleteConfirm = -1;
      this.dialogVisible = false;
    },
    handleEdit(data) {
      this.selectedCategory = {
        title: data.category_title,
        id: data.category_id,
        thumbnail: data.category_thumbnail,
        description: data.category_description
      };
      this.dialogVisible = true;
    },
  },
};
</script>
