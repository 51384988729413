<template>
  <v-data-table
    :headers="headers"
    :items="data"
    item-key="title"
    class="elevation-1"
    :search="search"
    :footer-props="{
      itemsPerPageText: 'عدد الأسطر في الصفحة:',
    }"
    :loading="loading"
  >
    <template v-slot:top>
      <div class="d-flex justify-end align-center">
        <v-text-field
          v-model="search"
          label="بحث"
          class="mx-4"
          :prepend-inner-icon="searchIcon"
          single-line
        ></v-text-field>
        <slot></slot>
      </div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-row align="center" justify="center">
        <slot name="action" :data="item"></slot>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BactTable",
  props: {
    headers: Array,
    moduleName: String,
    totalCount: String,
    loadingTable: Boolean,
    loadingTableText: String,
  },
  data() {
    return {
      search: "",
      totalItems: this.faqsCount,
      items: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch(`${this.moduleName}/fetchData`);
    },
  },

  computed: {
    loading() {
      return this.$store.getters[`${this.moduleName}/loading`];
    },
    data() {
      console.log( this.$store.getters[`${this.moduleName}/data`])
      return this.$store.getters[`${this.moduleName}/data`];
    }
  },
};
</script>
